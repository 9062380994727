import keyBy from 'lodash/keyBy';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';
import { AxiosResponse } from 'axios';
import uniq from 'lodash/uniq';
import store from '@/store';
import * as API from '@/api/projects.api';
import { First } from '@/types/util.types';
import { Environments } from '@/constants';
import { EnvMeta, Platform, ProjectEnvironmentConnectedService, secretKeyValPair } from '@/types/projects.types';
import sortBy from 'lodash/sortBy';
import { EnvironmentType } from '@/types/enum.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import first from 'lodash/first';
import { getAllProxyPath, getAllProjectEnvironmentUpstreamUri, isUpstreamUriUnique, getListingForRegisterId } from '@/api/registration.api';
export interface ProjectDetailsState {
  projectDetails: API.Projects.Project;
  projectLoading: boolean;
  projectPermissionsLoading: boolean;
  purpose: string;
  oauthType?: string;
}

function getEmptyProjectState(): API.Projects.Project {
  return {
    projectId: -1,
    projectName: '',
    projectDescription: '',
    businessUnitId: '',
    businessSubUnit: '',
    oauthType: '',
    createTimestamp: '',
    currentUserRoleId: -1,
    statusId: -1,
    statusName: '',
    lastUpdateUser: {
      firstName: '',
      lastName: '',
      mudId: '',
      email: '',
    },
    budgetingSource: {
      id: '',
      name: '',
      person: '',
      type: ''
    },
    publisherUser: {
      firstName: '',
      lastName: '',
      mudId: '',
      email: '',
    },
    environments: [],
    projectUsers: [],
    teams: [],
    bots: [],
    platform: {} as Platform,
  };
}

function getInitialMeta(): EnvMeta {
  return {
    meta: {
      loading: false,
      error: false,
      initial: true,
    },
  };
}

function getEmptyProjectEnv(): API.Projects.ProjectEnvironmentDetails2 {
  return {
    rpa: {
      meta: getInitialMeta().meta,
      bots: [],
    },
    auth: {
      meta: getInitialMeta().meta,
      OAuth: {
        oauthType: '',
        clientId: '',
        secret: [],
        callbackUrls: [],
        ssoSetting: {
          clientSecrets: [],
          id: '',
        },
        airAppUrl: ''
      },
      basic: {
        apiKeys: [],
      },
    },
    services: {
      connectedServices: [],
      meta: getInitialMeta().meta,
    },
    registrations: {
      services: [],
      meta: getInitialMeta().meta,
    },
    status: {
      statusId: -1,
      statusName: '',
      meta: getInitialMeta().meta,
    },
    connections: {
      meta: getInitialMeta().meta,
      // AIRAPP: {
      //   statusId: -1,
      //   statusMnemonic: '',
      //   connectionTypeMnemonic: 'AIRAPP',
      //   statusName: '',
      //   projectEnvironmentConnectionId: -1,
      //   connectionDetails: {},
      // },
      GITHUB: {
        statusId: -1,
        statusMnemonic: '',
        connectionTypeMnemonic: 'GITHUB',
        statusName: '',
        projectEnvironmentConnectionId: -1,
        connectionDetails: {
          id: 1,
        },
      },
      PIPELINE: {
        statusId: -1,
        statusMnemonic: '',
        connectionTypeMnemonic: 'PIPELINE',
        statusName: '',
        projectEnvironmentConnectionId: -1,
        connectionDetails: {
          organizationName: '',
          projectId: '',
          projectUrl: '',
          id: 1,
          webUrl: '',
          editorUrl: '',
          badgeUrl: '',
        },
      },
    },
  };
}

function addEnvDefaults(projectResult: AxiosResponse<API.Projects.Project>) {
  // Add QA and Prod dummy environments if they don't already exist
  const environments = projectResult.data.environments || [];
  if (!environments.find((env): boolean => env.environmentName === Environments.Qa)) {
    // create dummy record
    environments.push({
      environmentId: 2,
      environmentName: Environments.Qa,
      projectEnvironmentId: -1,
      statusName: 'Not Initialized',
      statusId: -1,
      sequenceNumber: 2,
      environmentTypeMnemonic: 'API',
      environmentTypeId: 1,
      mnemonic: 'QA',
      displayName: 'QA',
    });
  }
  if (!environments.find((env): boolean => env.environmentName === Environments.Prod)) {
    // create dummy record
    environments.push({
      environmentId: 3,
      environmentName: Environments.Prod,
      projectEnvironmentId: -1,
      statusName: 'Not Initialized',
      statusId: -1,
      sequenceNumber: 3,
      environmentTypeMnemonic: 'API',
      environmentTypeId: 1,
      mnemonic: 'DEV',
      displayName: 'Dev',
    });
  }
  return projectResult.data;
}

type ProjectEnvs = Record<number, Record<number, API.Projects.ProjectEnvironmentDetails2>>;

type PromoteEnvs = {
  Dev: boolean,
  QA: boolean,
  Prod: boolean
}

@Module({ dynamic: true, store, name: 'projectDetails', namespaced: true })
class ProjectDetails extends VuexModule implements ProjectDetailsState {
  public projectDetails: ProjectDetailsState['projectDetails'] = getEmptyProjectState();
  public projectLoading = false;
  public projectPermissionsLoading = false;
  public authDetailsLoading = false;
  public clientSecretDeleteLoading = false;
  public clientSecretResetLoading = false;
  public clientSecretSavingLoading = false;
  public apiKeyDeleteLoading = false;
  public apiKeyAddLoading = false;
  public apiKeySavingLoading = false;
  public promoteEnvironments: PromoteEnvs = {
    'Dev':false,
    'QA':false,
    'Prod':false
  }

  /**
   * Structure of this is
   * { [projectId]: { [projectEnvId]: ProjectEnvironmentDetails } }
   */
  public envs: ProjectEnvs = {};
  // envs ->

  public oAuthMneumonics: Record<number, Record<number, any>> = {};

  /**
   * The reason I put the following property and 2 methods
   * is to "save" the business purpose from the promote to prod
   * screen when you click to edit.
   */
  public purpose = '';
  public proxyPathList = {};
  public upstreamUriList: string[] = [];
  public get isInitialState(): boolean {
    return this.projectDetails.projectId === -1;
  }

  public get projectEnvsByProjectEnvId() {
    return keyBy(this.projectDetails.environments, penv => penv.projectEnvironmentId);
  }

  @Mutation
  public ensureProjectEnvironment(payload: {
    projectId: number;
    projectEnvId: number;
    partName: keyof API.Projects.ProjectEnvironmentDetails2;
  }) {
    const project = this.envs[payload.projectId];
    const env = project && project[payload.projectEnvId];
    if (!project) {
      Vue.set(this.envs, payload.projectId, {});
    }

    if (!env) {
      Vue.set(this.envs[payload.projectId], payload.projectEnvId, getEmptyProjectEnv());
    }
  }

  @Mutation
  public setProjectEnvironmentPartMeta(payload: {
    projectId: number;
    projectEnvId: number;
    partName: keyof API.Projects.ProjectEnvironmentDetails2;
    meta: Partial<API.Projects.EnvMeta['meta']>;
  }) {
    // ensure mutation should be called before this one
    const part = this.envs[payload.projectId][payload.projectEnvId][payload.partName];

    part.meta = {
      ...part.meta,
      ...payload.meta,
    };
  }

  @Mutation
  public setProjectEnvironmentPart(
    payload: { envPart: API.Projects.ProjectEnvironmentPart } & {
      projectId: number;
      projectEnvId: number;
      partName: keyof API.Projects.ProjectEnvironmentDetails2;
    },
  ) {
    // ensure mutation should be called before this one
    const projectEnvs = this.envs[payload.projectId];
    const oldEnv = projectEnvs && projectEnvs[payload.projectEnvId];
    const part: API.Projects.ProjectEnvironmentPart & API.Projects.EnvMeta = {
      ...payload.envPart,
      meta: {
        loading: false,
        error: false,
        initial: false,
      },
    };
    // this.setOAuthMneumonic(payload.projectId, payload.projectEnvId, 'AD_OAUTH')
    Vue.set(projectEnvs, payload.projectEnvId, {
      ...oldEnv,
      [payload.partName]: part,
    });
  }

  @Action
  public async getProjectEnvironmentPart(
    payload: First<Parameters<typeof API.getProjectEnvStatus>> & {
      partName: keyof API.Projects.ProjectEnvironmentDetails2;
    },
  ) {
    this.ensureProjectEnvironment(payload);
    this.setProjectEnvironmentPartMeta({ ...payload, meta: { loading: true } });
    const api = {
      auth: API.getProjectEnvAuth,
      services: API.getProjectEnvServices,
      status: API.getProjectEnvStatus,
      registrations: API.getProjectEnvRegistrations,
      connections: API.getProjectEnvConnections,
      rpa: API.getProjectEnvRpa,
    };
    const f = api[payload.partName];
    try {
      const r = await f(payload);
      this.setProjectEnvironmentPart({ ...payload, envPart: r.data });
    } catch (e) {
      this.setProjectEnvironmentPartMeta({ ...payload, meta: { error: true } });
      this.setProjectEnvironmentPartMeta({
        ...payload,
        meta: { loading: false },
      });
    }
  }

  @Mutation
  public setBusinessPurpose(newval: string): void {
    this.purpose = newval;
  }

  @Mutation
  public setOAuthMneumonic(payload: {projectId: number, projectEnvId: number, oAuthType: string}): void {
    const projectEnvId = payload.projectEnvId;
    const oAuthType = payload.oAuthType;
    const projectId = payload.projectId
    const oAuthTypeObj = {
      oAuthType
    };

    const projectEnv = {
      [projectEnvId]: oAuthTypeObj
    }
    this.oAuthMneumonics = {
      [projectId] : projectEnv
    };
  }

  public get apiEnvs() {
    return this.projectDetails.environments.filter(
      env => env.environmentTypeMnemonic === EnvironmentType.API,
    );
  }

  public get rpaEnvs() {
    const { A360 } = EnumsModule.enums.botSystems;
    const envs = this.projectDetails.environments.filter(env => {
      const envMatched =
        env.environmentTypeMnemonic === EnvironmentType.RPA &&
        this.projectDetails.bots.find(bot => {
          const matchingBot = bot.environments.find(botEnv => {
            const hasEnv = botEnv.mnemonic === env.mnemonic;
            const isA360 = botEnv.systems.length === 1 && botEnv.systems.includes(A360.mnemonic);
            if (!FeatureFlagsModule.a360Enabled) {
              return hasEnv && !isA360;
            }
            return hasEnv;
          });
          return matchingBot;
        });
      return envMatched;
    });
    return sortBy(envs, 'sequenceNumber');
  }

  public get defaultEnv(): API.Projects.ProjectEnvironment | void {
    const { environments = [] } = this.projectDetails;
    const rpa = 'RPAPROJECTENVPROVISIONED';
    const other = 'PROVISIONED';
    const ids = {
      rpa: EnumsModule.enums.status[rpa].id,
      other: EnumsModule.enums.status[other].id,
    };
    const rpaEnvs = sortBy(environments, 'sequenceNumber')
      .filter(env => ids.rpa === env.statusId)
      .reverse();
    const otherEnvs = sortBy(environments, 'sequenceNumber').filter(
      env => ids.other === env.statusId,
    );
    if (rpaEnvs.length || otherEnvs.length) {
      const { A360 } = EnumsModule.enums.botSystems;
      const filteredRpaEnvs = rpaEnvs.filter(env => {
        const envMatched =
          env.environmentTypeMnemonic === EnvironmentType.RPA &&
          this.projectDetails.bots.find(bot => {
            return bot.environments.find(botEnv => {
              const hasEnv = botEnv.mnemonic === env.mnemonic;
              const isA360 = botEnv.systems.length === 1 && botEnv.systems.includes(A360.mnemonic);
              if (!FeatureFlagsModule.a360Enabled) {
                return hasEnv && !isA360;
              }
              return hasEnv;
            });
          });
        return envMatched;
      });
      return first(otherEnvs.concat(filteredRpaEnvs));
    }

    return first(environments);
  }

  // dev api env
  public get devEnv(): API.Projects.ProjectEnvironment | undefined {
    return this.apiEnvs.find((env): boolean => env.environmentName === Environments.Dev);
  }

  public get cicdEnv(): API.Projects.ProjectEnvironment | undefined {
    const { environments = [] } = this.projectDetails;
    // bad magic string, need all the mnemonics in an enum
    return environments.find((env): boolean => env.environmentTypeMnemonic === 'CICD');
  }

  public get activeEnvTypes(): Record<string, true> {
    return uniq(this.projectDetails.environments.map(env => env.environmentTypeMnemonic)).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as Record<string, true>,
    );
  }

  @Mutation
  public setEmptyProject(): void {
    this.projectDetails = getEmptyProjectState();
  }

  @Mutation
  public setProject(projectDetails: API.Projects.Project): void {
    this.projectDetails = projectDetails;
  }

  @Mutation
  public setProjectLoading(isLoading: boolean): void {
    this.projectLoading = isLoading;
  }

  @Mutation
  public setAuthDetailsLoading(isLoading: boolean): void {
    this.authDetailsLoading = isLoading;
  }

  @Mutation
  public setClientSecretDeleteLoading(isLoading: boolean): void {
    this.clientSecretDeleteLoading = isLoading;
  }

  @Mutation
  public setClientSecretResetLoading(isLoading: boolean): void {
    this.clientSecretResetLoading = isLoading;
  }

  @Mutation
  public setProjectPermissionsLoading(isLoading: boolean): void {
    this.projectPermissionsLoading = isLoading;
  }

  @Mutation
  public setApiKeyDeleteLoading(isLoading: boolean): void {
    this.apiKeyDeleteLoading = isLoading;
  }

  @Mutation
  public setApiKeyAddLoading(isLoading: boolean): void {
    this.apiKeyAddLoading = isLoading;
  }


  @Mutation
  public setPromoteButtonStatus(status: {isLoading: boolean, env: string}): void {
    (this.promoteEnvironments as any)[status.env] = status.isLoading;
  }

  @Mutation
  public setClientSecretSavingLoading(isLoading: boolean): void {
    this.clientSecretSavingLoading = isLoading;
  }

  @Action({ rawError: true })
  public async getProject(projectId: string | number): Promise<API.Projects.Project> {
    this.setProjectLoading(true);
    this.setProjectPermissionsLoading(true);
    return this.baseGetProject(projectId).finally(() => {
      this.setProjectLoading(false);
      this.setProjectPermissionsLoading(false);
    });
  }

  @Action({ rawError: true })
  public async baseGetProject(projectId: string | number): Promise<API.Projects.Project> {
    return API.getProjectDetails(projectId)
      .then(projectResult => addEnvDefaults(projectResult))
      .then(project => {
        this.setProject(project);
        return project;
      });
  }

  @Action
  public async updateProjectDetails(
    payload: First<Parameters<typeof API.updateProjectDetails>>,
  ): Promise<void> {
    this.setProjectLoading(true);
    await API.updateProjectDetails(payload);
    await this.getProject(payload.projectId);
  }

  @Action
  public async updateProjectPermissions(
    // eslint-disable-next-line max-len
    payload: First<Parameters<typeof API.updateProjectPermissions>> & {
      skipGet: boolean;
    },
  ): Promise<void> {
    this.setProjectPermissionsLoading(true);
    await API.updateProjectPermissions(payload);
    if (!payload.skipGet) {
      await this.getProject(payload.projectId);
    }
  }

  @Action({ rawError: true })
  public async promoteEnvironment(
    payload: First<Parameters<typeof API.promoteProjectEnvironment>>,
  ): Promise<API.ProjectEnvironmentPromotion> {
    const result = await API.promoteProjectEnvironment(payload);
    await this.baseGetProject(this.projectDetails.projectId);
    if(result.data.statusName == 'Provisioning' || result.data.statusName == 'Provisioned')
    {
      this.setPromoteButtonStatus({isLoading: true, env: 'Dev'});
    }
    return result.data;
  }

  @Action({rawError: true})
  public async deleteProject(projectId: string | number): Promise<void> {
    const result: any = await API.deleteProject(projectId);
     if(result.response && result.response.status !== 200) {
       return Promise.reject(result.response.data.error);
     }
    this.setEmptyProject();
  }

  @Mutation
  public setConnectedServices(payload: {
    projectId: number;
    projectEnvId: number;
    connectedServices: ProjectEnvironmentConnectedService[];
  }) {
    const env = this.envs[payload.projectId][payload.projectEnvId];
    env.services.connectedServices = payload.connectedServices;
  }

  @Action({ rawError: true })
  public async removeConnectedService(payload: {
    projectId: number;
    projectEnvId: number;
    serviceEnvId: number;
    connectedServices: ProjectEnvironmentConnectedService[];
  }) {
    const { projectId, projectEnvId, serviceEnvId } = payload;
    const partName = 'services';
    this.setProjectEnvironmentPartMeta({
      projectId,
      projectEnvId,
      partName,
      meta: { loading: true },
    });
    API.removeServiceFromProject({ projectId, projectEnvId, serviceEnvId })
      .then(() => {
        this.setConnectedServices(payload);
        this.setProjectEnvironmentPartMeta({
          projectId,
          projectEnvId,
          partName,
          meta: { loading: false },
        });
      })
      .catch(err => {
        return new Promise((_, reject) => {
          this.setProjectEnvironmentPartMeta({
            projectId,
            projectEnvId,
            partName,
            meta: { error: true, loading: false },
          });
          reject(err);
        });
      });
  }

  @Mutation
  public setCallbackUrls(payload: { projectId: number; projectEnvId: number; urls: string[] }) {
    const env = this.envs[payload.projectId][payload.projectEnvId];
    env.auth.OAuth.callbackUrls = payload.urls;
  }

  @Mutation
  public setProxyPathData(proxyPathData: any): any {
    this.proxyPathList = proxyPathData;
  }

  @Mutation
  public setUpstreamUriData(data: any): any {
    this.upstreamUriList = data.data || [];
  }

  @Action({ rawError: true })
  public async updateCallbackUrls(payload: {
    projectId: number;
    projectEnvId: number;
    urls: string[];
  }) {
    this.setProjectEnvironmentPartMeta({
      projectId: payload.projectId,
      projectEnvId: payload.projectEnvId,
      partName: 'auth',
      meta: { loading: true },
    });
    API.updateCallbackUrls(payload.projectEnvId, {
      urls: payload.urls,
    })
      .then(res => {
        const urls = res.data.connectionDetails.callbackUrls;
        this.setCallbackUrls({ ...payload, urls });
        this.setProjectEnvironmentPartMeta({
          projectId: payload.projectId,
          projectEnvId: payload.projectEnvId,
          partName: 'auth',
          meta: { loading: false },
        });
      })
      .catch(err => {
        return new Promise((_, reject) => {
          this.setProjectEnvironmentPartMeta({
            projectId: payload.projectId,
            projectEnvId: payload.projectEnvId,
            partName: 'auth',
            meta: { error: true, loading: false },
          });
          reject(err);
        });
      });
  }

  @Action
  public async createNewClientSecret(data: {
    envId: number;
    ssoSettingId: string;
    description: string;
  }): Promise<void> {
    const payload = {
      envId: data.envId,
      ssoSettingId: data.ssoSettingId,
    };
    await API.addNewSecret(payload, data.description);
  }

  @Action
  public async createNewApiKey(data: {
    envId: number;
    name: string;
  }): Promise<void> {
    const payload = {
      envId: data.envId,
    };
    await API.addNewApiKey(payload, data.name);
  }

  @Action
  public async deleteClientSecret(data: {
    envId: number;
    ssoSettingId: string;
    clientSecretId: string;
  }): Promise<void> {
    await API.deleteClientSecret(data);
  }

  @Action
  public async deleteApiKey(data: {
    envId: number;
    apiKeyId: string;
  }): Promise<void> {
    await API.deleteApiKey(data);
  }

  @Action
  public async resetClientSecret(data: { envId: number; ssoSettingId: string }): Promise<void> {
    await API.resetClientSecret(data);
  }

  @Action
  public async downloadInvictiReport(scanId: string): Promise<any> {
    return await API.getInvictiScanReport(scanId);
  }

  @Action
  public async modifySecretCredential(data: {envId: number, clientId: string, secrets: string | secretKeyValPair[]}) {
    const {envId, clientId, secrets} = data;
    return API.modifySecretCredential({envId}, {clientId, secrets});
  }

  @Action
  public async triggerAPISecurityScan(data: {serviceId: number, projectEnvId: number}) {
    const {serviceId, projectEnvId} = data;
    return API.triggerAPISecurityScan({serviceId, projectEnvId});
  }

  @Action({ commit: 'setProxyPathData' })
  public async allProxyPath(platform: string) {
    return getAllProxyPath(platform);
  }

  @Action({ commit: 'setUpstreamUriData' })
  public async allProjectEnvironmentUpstreamUri(envId: number) {
    return getAllProjectEnvironmentUpstreamUri({ projectEnvId: envId });
  }
}

export const ProjectDetailsModule = getModule(ProjectDetails);
